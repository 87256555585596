import React from "react";
import logo from "../images/QuorumLogo.png";

const Logo = () => {
  return (
    <img 
      className="d-inline-block align-top" 
      src={logo} 
      alt="Logo" 
      width="100px" 
      style={{ borderRadius: "7px" }}
    />
  );
};

export default Logo;

