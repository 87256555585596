import React from "react";
import "../scss/_hero.scss";

const Hero = () => (
  <div className="hero">
    <div className="heroTextBox">
      <h1>Your AI Legislative Aide</h1>
      <p className="subtext">Frustrated with politics? Take action.</p>
      <p>Write real legislation in minutes—<em>no legal jargon, no gatekeepers.</em></p>
      <p className="strong-text">Plan. Write. Share. Get your ideas heard.</p>
      <a href="/profile" className="cta-button">Start Writing</a>
      <p className="cta-subtext">Free to start. Make an impact today.</p>
    </div>
  </div>
);

export default Hero;
